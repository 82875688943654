.navigation {
    display: flex;
    opacity: 0;
    position: fixed;
    pointer-events: none;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    padding-bottom: 1em;
    background: map-deep-get($colors, "primary", "default");
    transition: $transition-default;
    $navigation: &;

    @include media-breakpoint-up(md) {
        opacity: 1;
        position: relative;
        flex-flow: row;
        justify-content: flex-end;
        background: none;
        pointer-events: all;
    }

    &__list {
        display: flex;
        flex-flow: column;
        align-self: center;

        @include media-breakpoint-up(md) {
            flex-flow: row;
            align-self: flex-end;
        }
    }

    &__listitem {
        opacity: 0;
        position: relative;
        text-align: center;
        transition: $transition-default;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            bottom: rem-calc(-2);
            left: 0;
            width: 0;
            height: rem-calc(11);
            font-weight: 700;
            background-color: map-deep-get($colors, "primary", "default");
            transition: $transition-default;
        }

        @include media-breakpoint-up(md) {
            opacity: 1;
            text-align: left;
            margin-right: 20px;

            &--active,
            &:hover {
                &::after {
                    width: 100%;
                }
            }
        }
    }

    &__link {
        display: inline-block;
        padding: rem-calc(10) 0;
        color: map-deep-get($colors, "black", "default");
        font-size: rem-calc(22);
        text-decoration: none;

        &--active {
            font-weight: 700;
        }

        @include media-breakpoint-up(md) {
            padding: 0;
            font-size: rem-calc(16);
        }
    }

    &--menuExpanded {
        opacity: 1;
        pointer-events: all;

        #{$navigation} {
            &__list {
                transform: translateX(0);
                transition: opacity .3s ease-in-out, transform 0s linear;
            }

            &__listitem {
                transform: translateY(0);
                opacity: 1;

                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        transition-delay: $i * .05s;
                    }
                }
            }
        }
    }
}