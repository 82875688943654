.footer {
    padding: rem-calc(60) 0;
    background: map-deep-get($colors, "secondary", "dark");
    flex: 1 1 100%;

    &__copyright-nav {
        color: map-deep-get($colors, "white", "default");

        @include media-breakpoint-up(md) {
            display: flex;
        }
    }

    &__nav {
        margin: 20px 0;

        @include media-breakpoint-up(md) {
            margin: 0 0 0 20px;
        }
    }

    &__webnl {
        color: map-deep-get($colors, "white", "default");

        @include media-breakpoint-up(md) {
            text-align: right;
        }
    }

    /* stylelint-disable */
    h2, h5, a {
        color: map-deep-get($colors, "white", "default");
    }

    input {
        color: map-deep-get($colors, "secondary", "dark");
        background-color: map-deep-get($colors, "white", "default");

        &::placeholder {
            color: #a9aeb2;
        }
    }
    /* stylelint-enable */
}
