.exhibition-card {
    display: block;
    margin-top: 1.5em;
    text-decoration: none;
    $block: &;

    &:hover {
        #{$block}__title {
            text-decoration: underline;
        }
    }

    &__title {
        margin-bottom: 0;
    }
}