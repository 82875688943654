.modal {
    $modal: &;
    position: relative;
    color: map-deep-get($colors, "black", "default");

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: rem-calc(10);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: map-deep-get($colors, "gray", "default");

        svg {
            width: rem-calc(16);
            height: rem-calc(16);
        }

        svg path {
            fill: map-deep-get($colors, "black", "default");
        }
    }

    &__body {
        margin: 20px 0;
    }

    /* stylelint-disable */

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal__container,
    .modal-leave-active .modal__container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    flex-flow: column;
    transition: opacity .3s ease;
}

.modal-wrapper {
    width: 100%;
}

.modal-container {
    max-width: 100%;
    width: 600px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

/* stylelint-enable */
