.painting-share {
    &__list {
        margin-top: rem-calc(10);
    }

    &__listitem {
        padding-bottom: rem-calc(10);
    }

    &__link {
        text-decoration: none;

        svg {
            vertical-align: middle;
            margin-right: rem-calc(10);
        }

        &--facebook {
            svg path {
                fill: map-deep-get($colors, "social", "facebook");
            }
        }

        &--twitter {
            svg path {
                fill: map-deep-get($colors, "social", "twitter");
            }
        }

        &--linkedin {
            svg path {
                fill: map-deep-get($colors, "social", "linkedin");
            }
        }

        &--pinterest {
            svg path {
                fill: map-deep-get($colors, "social", "pinterest");
            }
        }

        &--instagram {
            svg path {
                fill: map-deep-get($colors, "social", "instagram");
            }
        }
    }
}