.painting-slider {
    &__card {
        flex: 1 1 100%;

        &--inner {
            color: #ffffff;
            background-position: center center;
            background-size: cover;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 100%;
            position: relative;
            z-index: 1;
            align-content: space-between;
            pointer-events: none;
            cursor: default;

            &::after {
                content: "";
                background: linear-gradient(360deg, #0d0d1c 0%, rgba(#0d0d1c, 0) 100%);
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &--nav {
        position: absolute;
        bottom: 5%;
        left: auto;
        right: 0;
        display: flex;
        justify-content: flex-end;
        margin-top: -25px;
        padding-left: 15px;
        padding-right: 15px;
    }

    &--nav-item {
        background-color: map-deep-get($colors, "primary", "default");
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
        margin: 0 1rem;
        display: inline-flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        transition: all .3s ease-in-out;
    }

    &__text {
        position: absolute;
        bottom: 10%;
        left: 15%;
        max-width: 300px;
        z-index: 1;
    }

    &__text-side {
        position: absolute;
        bottom: 3rem;
        transform: rotate(270deg);
        z-index: 1;

        @include media-breakpoint-up(md) {
            bottom: 4rem;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
    }

    /* stylelint-disable */
    .flickity-slider {
        display: flex;
    }

    .flickity-viewport {
        overflow: visible;
    }

    /* stylelint-enable */
}
