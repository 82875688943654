﻿h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.5em;
}

h1, .h1 {
    font-size: rem-calc(46);
    line-height: 0.85em;
    font-weight: 900;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(56);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(76);
    }
}

h2, .h2 {
    font-size: rem-calc(40);
    font-weight: 900;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(48);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(56);
    }
}

h3, .h3 {
    font-size: rem-calc(34);
    font-weight: 900;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(40);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(46);
    }
}

h4, .h4 {
    font-size: rem-calc(28);
    font-weight: 900;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(32);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(36);
    }
}

h5, .h5 {
    font-size: rem-calc(22);
    font-weight: 900;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(24);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(26);
    }
}

h6, .h6 {
    font-size: rem-calc(16);
    font-weight: 900;
}