@font-face {
    font-family: Avenir;
    src: url("../fonts/Avenir-Medium.woff2") format("woff2"),
        url("../fonts/Avenir-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Avenir;
    src: url("../fonts/Avenir-Black.woff2") format("woff2"),
        url("../fonts/Avenir-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: Avenir;
    src: url("../fonts/Avenir-Light.woff2") format("woff2"),
        url("../fonts/Avenir-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}
