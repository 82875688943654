/* stylelint-disable */
#CybotCookiebotDialog {
    #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
        &.CybotCookiebotDialogActive {
            border-bottom-color: $c-primary-default !important;
            color: $c-primary-default !important;
        }

        &:hover,
        &:focus-visible {
            color: $c-primary-default !important;
        }
    }

    .CybotCookiebotDialogDetailBodyContentCookieLink,
    #CybotCookiebotDialogBodyEdgeMoreDetailsLink,
    #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover,
    #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
        color: $c-primary-default !important;
    }

    #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
        &::after {
            border-color: $c-primary-default !important;
            position: relative;
            top: .0625rem;
        }
    }

    .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
        transition: $transition-default;

        &:hover,
        &:focus-visible {
            color: $c-primary-default !important;

            &:before {
                border-color: $c-primary-default !important;
            }
        }

        &::before {
            transition: $transition-default;
        }
    }


    .CybotCookiebotDialogBodyButton {
        background-color: $c-primary-default !important;
        border-color: $c-primary-default !important;
        color: $c-white-default !important;
        transition: $transition-default;

        &:hover,
        &:focus-visible {
            background-color: $c-primary-05 !important;
            border-color: $c-primary-75 !important;
            opacity: 1 !important;
        }
    }

    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
    #CybotCookiebotDialogBodyButtonDecline {
        color: $c-white-default !important;
        background-color: $c-primary-default !important;

        &:hover,
        &:focus-visible {
            background-color: $c-primary-75 !important;
        }
    }

    #CybotCookiebotDialogDetailFooter a {
        color: $c-primary-default !important;
    }

    input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
        background-color: $c-primary-default !important;
    }

    input[type=checkbox],
    input[type=radio] {
        z-index: 0;
    }
}

#CookiebotWidget {
    .CookiebotWidget-body .CookiebotWidget-consents-list svg {
        fill: $c-primary-default !important;
    }

    .CookiebotWidget-show-consent-details {
        color: $c-primary-default !important;

        svg {
            fill: $c-primary-default !important;
            position: relative;
            top: .0625rem;
        }
    }

    #CookiebotWidget-buttons button {
        background-color: $c-primary-default !important;
        border-color: $c-primary-default !important;
        color: $c-white-default !important;
        transition: $transition-default;

        &:hover,
        &:focus-visible {
            background-color: $c-primary-75 !important;
            border-color: $c-primary-75 !important;
            opacity: 1 !important;
        }
    }

    #CookiebotWidget-buttons #CookiebotWidget-btn-change {
        background-color: $c-primary-default !important;

        &:hover,
        &:focus-visible {
            background-color: $c-primary-75 !important;
        }
    }
}
/* stylelint-enable */
