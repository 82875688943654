.page {
    &--no-hero {
        padding-top: 150px;
    }

    &--text {
        font-size: rem-calc(22);

        /* stylelint-disable */
        .hero {
            margin-bottom: 0;
        }

        .newsletter {
            margin-top: 5rem;
        }

        h2 {
            margin: 0;
        }

        h5 {
            color: map-deep-get($colors, "white", "default");
        }

        input {
            background-color: map-deep-get($colors, "white", "default");

            &::placeholder {
                color: #a9aeb2;
            }
        }


        .feature-block {
            margin-top: 3rem;
            margin-bottom: 3rem;

            &__text {
                p {
                    margin-left: 5rem;
                }
            }
        }
        /* stylelint-enable */
    }
}
