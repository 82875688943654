.hero {
    height: 100vh;
    overflow: hidden;
    margin-bottom: 5em;

    &__image {
        position: relative;
        height: 100%;
        background: map-deep-get($colors, "gray", "default");

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 33.15%, rgba(0, 0, 0, 0) 65.75%, rgba(0, 0, 0, 0.4) 84%);
        }
    }

    &__imageleft > .hero__bg {
        background-position: left;
    }

    &__imagecenter > .hero__bg {
        background-position: center;
    }

    &__imageright > .hero__bg {
        background-position: right;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    &__content {
        position: relative;
        z-index: 12;
        transform: translateY(-99%);
        background: map-deep-get($colors, "secondary", "default");

        &::before {
            content: "";
            position: absolute;
            top: rem-calc(-29);
            left: 0;
            right: 0;
            height: rem-calc(30);
            background-color: map-deep-get($colors, "secondary", "default");
            mask-image: url("../images/brush-edge.svg");
            mask-size: 100% 100%;
        }
    }

    &__title {
        position: absolute;
        top: rem-calc(-60);
        transform: translateY(-100%);
        margin-bottom: .25em;
        color: map-deep-get($colors, "white", "default");
    }

    &__text {
        font-size: 22px;
        margin-bottom: 2em;
    }

    &__video-wrapper {
        display: flex;
        justify-content: center;
    }

    &__play-button {
        left: 50%;
        position: absolute;
        top: 20%;
        transform: translate(-50%, -50%);
        z-index: 1;

        @include media-breakpoint-up(sm) {
            top: calc(50% - 3.75rem);
        }

        svg {
            margin: 0;
        }
    }
}
