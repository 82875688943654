.main {
    overflow: hidden;
    max-width: 100%;
    transition: $transition-default;
    flex: 1 1 100%;

    /* stylelint-disable */
    .painting-how {
        .col-24 {
            padding: 0;
        }
    }

    .painting-how__title {
        display: flex;
        width: 100%;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: auto;
        margin-left: auto;

        @include media-breakpoint-up(lg) {
            max-width: 1200px;
        }
    }
    /* stylelint-enable */
}
