.hamburger {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 1em;

    @include media-breakpoint-up(md) {
        display: none;
    }

    &__box {
        align-self: flex-end;
    }
}