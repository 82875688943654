.form-message {
    background-color: map-deep-get($colors, "gray", "dark");
    border-radius: $border-radius;
    color: map-deep-get($colors, "white", "default");
    padding: 12px 46px 12px 12px;
    margin: rem-calc(32) 0;
    position: relative;
    align-items: center;
    display: none;
    min-height: rem-calc(48);
    $message: &;

    &--active {
        display: flex;
    }

    &--success {
        background-color: map-deep-get($colors, "validation", "valid");

        #{$message}__icon--success {
            display: flex;
        }
    }

    &--error {
        background-color: map-deep-get($colors, "validation", "invalid");

        #{$message}__icon--error {
            display: flex;
        }
    }

    &__icon {
        position: absolute;
        right: rem-calc(12);
        top: rem-calc(8);
        align-items: center;
        justify-content: center;
        z-index: 2;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: none;
        border: 1px solid map-deep-get($colors, "white", "default");

        svg {
            width: rem-calc(16);
            fill: map-deep-get($colors, "white", "default");
        }
    }

    // Wordt uit Back-End gehaald
    /* stylelint-disable */
    a {
        color: map-deep-get($colors, "white", "default");
        text-decoration: underline;
    }
    /* stylelint-enable */
}
