.socials {
    display: flex;

    &__listitem {
        position: relative;

        @include media-breakpoint-up(md) {
            margin-left: 20px;
        }
    }

    &__link {
        display: inline-block;
        padding: rem-calc(10) 0;
        color: map-deep-get($colors, "black", "default");
        font-size: rem-calc(22);
        text-transform: uppercase;
        text-decoration: none;

        &--active {
            font-weight: 700;
        }

        @include media-breakpoint-up(md) {
            padding: 0;
            font-size: rem-calc(16);
        }

        svg {
            vertical-align: middle;
        }

        svg path {
            fill: map-deep-get($colors, "black", "default");
        }
    }
}