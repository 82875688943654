.painting-detail {
    padding-top: 120px;
    $painting-detail: &;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-up(md) {
        display: flex;
    }

    @include media-breakpoint-up(lg) {
        max-width: 1200px;
    }

    &__image-wrapper {
        @include media-breakpoint-up(md) {
            margin-right: 20px;
            transition: $transition-default;
        }

        @media (min-width: 1440px) {
            max-width: 850px;
        }

        &--focus {
            position: fixed;
            z-index: 14;
            top: 0;
            left: 0;
            overflow: auto;
            padding-bottom: 80px;
            width: 100vw;
            max-width: none;
            height: 100vh;

            #{$painting-detail}__image {
                width: 100vw;
                max-height: none;
            }

            #{$painting-detail}__image-buttons {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 20px;
                background-color: map-deep-get($colors, "secondary", "default");
                transition: $transition-default;
            }

            #{$painting-detail}__image-button {
                margin-bottom: 0;
                transition: $transition-default;
            }

            @each $name, $color in $background-colors {
                #{$painting-detail}__image-buttons--color-#{$name} {
                    background-color: $color;
                }

                &#{$painting-detail}__image-wrapper--color-#{$name} {
                    background-color: $color;
                }
            }
        }
    }

    &__background-wrapper {
        position: relative;

        @include media-breakpoint-up(md) {
            max-height: calc(100vh - 180px);
        }
    }

    &__background-image {
        background-repeat: no-repeat;
        bottom: 0;
        cursor: zoom-in;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__image {
        max-width: 100%;

        @include media-breakpoint-up(md) {
            max-height: calc(100vh - 180px);
            transition: $transition-default;
        }
    }

    &__image-buttons {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            justify-content: space-between;
        }
    }

    &__image-buttons-navigation {
        flex-grow: 1;
    }

    &__image-buttons-focus {
        @include media-breakpoint-up(md) {
            min-width: 210px;
        }
    }

    &__image-button {
        display: inline-flex;
        justify-content: space-between;
        font-size: 0;
        font-weight: 700;
        padding: 4px 0;
        margin-right: 0;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(16);
            padding: 4px 24px 4px 0;
        }

        svg {
            display: inline-block;
            vertical-align: middle;
            margin: 0 10px 0 0;
        }

        &--next {
            svg {
                margin: 0 0 0 10px;
            }
        }

        &--previous,
        &--next {
            max-width: 45%;
        }
    }

    &__image-button-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-grow: 1;
    }

    &__caption {
        @include media-breakpoint-up(md) {
            width: 300px;
            justify-self: flex-end;
            margin-left: auto;
            padding-bottom: 20px;
        }
    }

    &__title {
        margin-bottom: rem-calc(33);
    }

    &__intro {
        padding-bottom: rem-calc(50px);
    }

    &__download-button {
        margin-bottom: 1.2em;
    }
}

/* stylelint-disable */
.rsbtn_play {
    background-color: map-deep-get($colors, "primary", "default") !important;
    border: none !important;
    padding: .25rem !important;
    border-radius: 3px !important;

    &:hover {
        background-color: #d97373 !important;
        transition: all .3s ease-in-out;

        .rsbtn_right::before {
            color: map-deep-get($colors, "white", "default") !important;
        }
    }
}

.rsbtn_left span,
.rsbtn_right {
    color: map-deep-get($colors, "white", "default") !important;
    border-left: none !important;
}

.rsbtn_tooltoggle {
    background-color: map-deep-get($colors, "primary", "default") !important;
    border: 0 !important;
    position: absolute !important;
    top: 50% !important;
    left: 0 !important;
    transform: translateY(-50%) !important;

    &:hover {
        background-color: #d97373 !important;
        transition: all .3s ease-in-out;
    }
}

.rs_addtools a,
.rs_addtools span,
.rsbtn a,
.rsbtn span {
    color: map-deep-get($colors, "white", "default") !important;
}

.rsbtn_tools_inner {
    background-color: map-deep-get($colors, "primary", "default") !important;
}

.rsbtn_tool {
    &:hover {
        background-color: #d97373 !important;
    }
}

.rsbtn_exp_inner {
    border: 0 !important;
    margin-top: 10px !important;
    border-radius: 3px !important;
    display: inline-flex !important;
}

.rs-player-loadindicator {
    display: none !important;
}

.rsbtn_status {
    background-color: map-deep-get($colors, "primary", "default") !important;
}

.rsbtn_player_item {
    background-color: map-deep-get($colors, "primary", "default") !important;
    border: 0 !important;

    &:hover {
        background-color: #d97373 !important;
    }
}

.rsbtn.rsexpanded {
    margin-bottom: 45px;
}

#rsbtn_scrollcontrols {
    background-color: map-deep-get($colors, "primary", "default") !important;
    border: 0 !important;

    button,
    .rsbtn_scroll_state {
        color: map-deep-get($colors, "white", "default") !important;
        border: 0 !important;

        &:hover,
        &:focus {
            background-color: #d97373 !important;
        }
    }
}
/* stylelint-enable */
