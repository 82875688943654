@font-face {
  font-family: Avenir;
  src: url("../fonts/Avenir-Medium.woff2") format("woff2"), url("../fonts/Avenir-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: Avenir;
  src: url("../fonts/Avenir-Black.woff2") format("woff2"), url("../fonts/Avenir-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: Avenir;
  src: url("../fonts/Avenir-Light.woff2") format("woff2"), url("../fonts/Avenir-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 992px) {
    .container {
      max-width: 1200px; } }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-13, .col-xxl-14, .col-xxl-15, .col-xxl-16, .col-xxl-17, .col-xxl-18, .col-xxl-19, .col-xxl-20, .col-xxl-21, .col-xxl-22, .col-xxl-23, .col-xxl-24, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 25; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.offset-1 {
  margin-left: 4.16667%; }

.offset-2 {
  margin-left: 8.33333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.66667%; }

.offset-5 {
  margin-left: 20.83333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.16667%; }

.offset-8 {
  margin-left: 33.33333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.66667%; }

.offset-11 {
  margin-left: 45.83333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.16667%; }

.offset-14 {
  margin-left: 58.33333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.66667%; }

.offset-17 {
  margin-left: 70.83333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.16667%; }

.offset-20 {
  margin-left: 83.33333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.66667%; }

.offset-23 {
  margin-left: 95.83333%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 25; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xxl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xxl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xxl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xxl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xxl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xxl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xxl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xxl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xxl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xxl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xxl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xxl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xxl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 25; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .order-xxl-13 {
    order: 13; }
  .order-xxl-14 {
    order: 14; }
  .order-xxl-15 {
    order: 15; }
  .order-xxl-16 {
    order: 16; }
  .order-xxl-17 {
    order: 17; }
  .order-xxl-18 {
    order: 18; }
  .order-xxl-19 {
    order: 19; }
  .order-xxl-20 {
    order: 20; }
  .order-xxl-21 {
    order: 21; }
  .order-xxl-22 {
    order: 22; }
  .order-xxl-23 {
    order: 23; }
  .order-xxl-24 {
    order: 24; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 4.16667%; }
  .offset-xxl-2 {
    margin-left: 8.33333%; }
  .offset-xxl-3 {
    margin-left: 12.5%; }
  .offset-xxl-4 {
    margin-left: 16.66667%; }
  .offset-xxl-5 {
    margin-left: 20.83333%; }
  .offset-xxl-6 {
    margin-left: 25%; }
  .offset-xxl-7 {
    margin-left: 29.16667%; }
  .offset-xxl-8 {
    margin-left: 33.33333%; }
  .offset-xxl-9 {
    margin-left: 37.5%; }
  .offset-xxl-10 {
    margin-left: 41.66667%; }
  .offset-xxl-11 {
    margin-left: 45.83333%; }
  .offset-xxl-12 {
    margin-left: 50%; }
  .offset-xxl-13 {
    margin-left: 54.16667%; }
  .offset-xxl-14 {
    margin-left: 58.33333%; }
  .offset-xxl-15 {
    margin-left: 62.5%; }
  .offset-xxl-16 {
    margin-left: 66.66667%; }
  .offset-xxl-17 {
    margin-left: 70.83333%; }
  .offset-xxl-18 {
    margin-left: 75%; }
  .offset-xxl-19 {
    margin-left: 79.16667%; }
  .offset-xxl-20 {
    margin-left: 83.33333%; }
  .offset-xxl-21 {
    margin-left: 87.5%; }
  .offset-xxl-22 {
    margin-left: 91.66667%; }
  .offset-xxl-23 {
    margin-left: 95.83333%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 5px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 20px;
  height: 14px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 20px;
    height: 2px;
    background-color: #000000;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -6px; }
  .hamburger-inner::after {
    bottom: -6px; }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger {
  z-index: 11; }
  .hamburger--inverted .hamburger-inner {
    background-color: #000000; }
    .hamburger--inverted .hamburger-inner::after, .hamburger--inverted .hamburger-inner::before {
      background-color: #000000; }
  .hamburger--tint-light .hamburger-inner {
    background-color: #000000; }
    .hamburger--tint-light .hamburger-inner::after, .hamburger--tint-light .hamburger-inner::before {
      background-color: #000000; }
  .hamburger--tint-dark .hamburger-inner {
    background-color: #ffffff; }
    .hamburger--tint-dark .hamburger-inner::after, .hamburger--tint-dark .hamburger-inner::before {
      background-color: #ffffff; }

.b-lazy {
  opacity: 0;
  transition: all 0.3s ease-in-out; }
  .b-lazy.b-loaded {
    opacity: 1; }

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: 0; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: #fff;
  cursor: pointer; }

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f; }

.flickity-button:active {
  opacity: .6; }

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: .25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.flickity-viewport {
  transition: height .3s ease-in-out; }

/* stylelint-disable */
#CybotCookiebotDialog #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom-color: #a61e19 !important;
  color: #a61e19 !important; }

#CybotCookiebotDialog #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover, #CybotCookiebotDialog #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:focus-visible {
  color: #a61e19 !important; }

#CybotCookiebotDialog .CybotCookiebotDialogDetailBodyContentCookieLink,
#CybotCookiebotDialog #CybotCookiebotDialogBodyEdgeMoreDetailsLink,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for=CybotCookiebotDialogBodyLevelButtonNecessary]) .CybotCookiebotDialogBodyLevelButtonDescription:hover,
#CybotCookiebotDialog #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
  color: #a61e19 !important; }

#CybotCookiebotDialog #CybotCookiebotDialogBodyEdgeMoreDetailsLink::after {
  border-color: #a61e19 !important;
  position: relative;
  top: .0625rem; }

#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton {
  transition: all 0.3s ease-in-out; }
  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover, #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:focus-visible {
    color: #a61e19 !important; }
    #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover:before, #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:focus-visible:before {
      border-color: #a61e19 !important; }
  #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton::before {
    transition: all 0.3s ease-in-out; }

#CybotCookiebotDialog .CybotCookiebotDialogBodyButton {
  background-color: #a61e19 !important;
  border-color: #a61e19 !important;
  color: #FFFFFF !important;
  transition: all 0.3s ease-in-out; }
  #CybotCookiebotDialog .CybotCookiebotDialogBodyButton:hover, #CybotCookiebotDialog .CybotCookiebotDialogBodyButton:focus-visible {
    background-color: rgba(166, 30, 25, 0.05) !important;
    border-color: rgba(166, 30, 25, 0.75) !important;
    opacity: 1 !important; }

#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialog #CybotCookiebotDialogBodyButtonDecline {
  color: #FFFFFF !important;
  background-color: #a61e19 !important; }
  #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:hover, #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:focus-visible,
  #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection:hover,
  #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection:focus-visible,
  #CybotCookiebotDialog #CybotCookiebotDialogBodyButtonDecline:hover,
  #CybotCookiebotDialog #CybotCookiebotDialogBodyButtonDecline:focus-visible {
    background-color: rgba(166, 30, 25, 0.75) !important; }

#CybotCookiebotDialog #CybotCookiebotDialogDetailFooter a {
  color: #a61e19 !important; }

#CybotCookiebotDialog input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: #a61e19 !important; }

#CybotCookiebotDialog input[type=checkbox],
#CybotCookiebotDialog input[type=radio] {
  z-index: 0; }

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list svg {
  fill: #a61e19 !important; }

#CookiebotWidget .CookiebotWidget-show-consent-details {
  color: #a61e19 !important; }
  #CookiebotWidget .CookiebotWidget-show-consent-details svg {
    fill: #a61e19 !important;
    position: relative;
    top: .0625rem; }

#CookiebotWidget #CookiebotWidget-buttons button {
  background-color: #a61e19 !important;
  border-color: #a61e19 !important;
  color: #FFFFFF !important;
  transition: all 0.3s ease-in-out; }
  #CookiebotWidget #CookiebotWidget-buttons button:hover, #CookiebotWidget #CookiebotWidget-buttons button:focus-visible {
    background-color: rgba(166, 30, 25, 0.75) !important;
    border-color: rgba(166, 30, 25, 0.75) !important;
    opacity: 1 !important; }

#CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
  background-color: #a61e19 !important; }
  #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change:hover, #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change:focus-visible {
    background-color: rgba(166, 30, 25, 0.75) !important; }

/* stylelint-enable */
*, *::before, *::after {
  box-sizing: border-box; }

*:focus {
  outline: none; }

html, body {
  margin: 0 auto;
  padding: 0;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-weight: 200;
  color: #000000;
  background: #efece5; }

/* stylelint-disable */
span, p, i, b, u, strong, strike {
  font-family: "Avenir", Helvetica, Arial, sans-serif !important; }

/* stylelint-enable */
.body--color-puur-bruin {
  background-color: #7b6d5f; }

.body--color-zacht-bruin {
  background-color: #baad9c; }

.body--color-puur-oker {
  background-color: #b29263; }

.body--color-puur-terra {
  background-color: #936d53; }

.body--color-terra {
  background-color: #be906a; }

.body--color-zacht-terra {
  background-color: #d8bfa2; }

.body--color-puur-rood {
  background-color: #78403a; }

.body--color-zacht-rood {
  background-color: #d3c0b1; }

.body--color-wit-grijs {
  background-color: #efece5; }

.body--color-licht-grijs {
  background-color: #cbc9bc; }

.body--color-koel-grijs {
  background-color: #c7c7c5; }

.body--color-midden-grijs {
  background-color: #a9aeb2; }

.body--color-groen-grijs {
  background-color: #b7aaa0; }

.body--color-warm-grijs {
  background-color: #7d7a75; }

.body--color-donker-grijs {
  background-color: #686667; }

.body--color-zwart-grijs {
  background-color: #36373c; }

.body--color-groen {
  background-color: #7f8270; }

.body--color-puur-groen {
  background-color: #555c55; }

.body--color-zacht oker {
  background-color: #dacebd; }

.body--color-oker {
  background-color: #c1aa88; }

.body--color-zwart {
  background-color: #000000; }

.body--tint-dark {
  color: #ffffff;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .body--tint-dark a {
    color: #ffffff; }
  .body--tint-dark .button--ghost svg path {
    fill: #ffffff; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a {
  color: #000000; }

b, strong {
  font-weight: 700; }

::selection {
  background-color: #a61e19;
  color: #ffffff; }

[v-cloak] {
  display: none; }

.button {
  position: relative;
  padding: 4px 12px;
  margin-bottom: 12px;
  margin-right: 24px;
  min-height: 2.5rem;
  border-radius: 3px;
  background-color: #a61e19;
  color: #ffffff;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-size: 22px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none; }
  @media (min-width: 768px) {
    .button {
      font-size: 26px; } }
  .button svg {
    width: 1.875rem;
    height: 1.875rem;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.3s ease-in-out; }
  .button svg path {
    fill: #ffffff; }
  .button::after {
    content: "";
    position: absolute;
    bottom: -0.75rem;
    left: -1px;
    right: -1px;
    height: 0.9375rem;
    background-color: #a61e19;
    mask-image: url("../images/brush-stroke.svg");
    mask-size: 100% 100%;
    transition: all 0.3s ease-in-out; }
  .button:hover, .button:focus {
    background-color: #d97373; }
    .button:hover::after, .button:focus::after {
      background-color: #d97373; }
  .button--wide {
    width: 100%;
    justify-content: center; }
  .button--small {
    font-size: 16px;
    min-height: 1.875rem; }
  .button--ghost {
    color: #000000;
    background: transparent; }
    .button--ghost svg path {
      fill: #000000; }
    .button--ghost:hover, .button--ghost:focus {
      background: transparent; }
      .button--ghost:hover svg, .button--ghost:focus svg {
        transform: scale(1.3); }
    .button--ghost::after {
      display: none; }
  .button--light svg path {
    fill: #000000; }
  .button--black {
    background-color: #000000; }
    .button--black::after {
      background-color: #000000; }
  .button:not(.button--ghost) {
    color: #ffffff; }

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.5em; }

h1, .h1 {
  font-size: 2.875rem;
  line-height: 0.85em;
  font-weight: 900; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 3.5rem; } }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 4.75rem; } }

h2, .h2, .painting-filter__title select {
  font-size: 2.5rem;
  font-weight: 900; }
  @media (min-width: 768px) {
    h2, .h2, .painting-filter__title select {
      font-size: 3rem; } }
  @media (min-width: 992px) {
    h2, .h2, .painting-filter__title select {
      font-size: 3.5rem; } }

h3, .h3 {
  font-size: 2.125rem;
  font-weight: 900; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 2.5rem; } }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 2.875rem; } }

h4, .h4 {
  font-size: 1.75rem;
  font-weight: 900; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 2rem; } }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 2.25rem; } }

h5, .h5 {
  font-size: 1.375rem;
  font-weight: 900; }
  @media (min-width: 768px) {
    h5, .h5 {
      font-size: 1.5rem; } }
  @media (min-width: 992px) {
    h5, .h5 {
      font-size: 1.625rem; } }

h6, .h6 {
  font-size: 1rem;
  font-weight: 900; }

/* stylelint-disable */
.rich-text-area .intro {
  margin-top: 0;
  margin-bottom: 1.75em; }

.rich-text-area p {
  margin: 0 0 1em; }

.rich-text-area h1, .rich-text-area h2, .rich-text-area h3, .rich-text-area h4, .rich-text-area h5, .rich-text-area h6 {
  margin: 1em 0 1em; }

.rich-text-area img {
  max-width: 100%; }

/* stylelint-enable */
.barba-bg {
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #efece5;
  margin-left: 100%;
  opacity: 0;
  transition: margin 0s linear .3s, opacity .3s ease-in-out; }
  .barba-bg--visible {
    opacity: 1;
    margin-left: 0;
    transition: margin 0s linear, opacity .3s ease-in-out; }

.barba-wrapper {
  height: 100%; }

.barba-container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%; }

input, select {
  height: 2.5rem;
  /* stylelint-disable */
  margin-bottom: 0 !important;
  /* stylelint-enable */
  padding: 0 1rem;
  width: 100%;
  color: #fffcf4;
  transition: all 0.3s ease-in-out;
  border: 1px solid #aca597;
  background-color: #aca597; }
  input:focus, select:focus {
    outline: none;
    border: 1px solid #000000; }
  input::placeholder, select::placeholder {
    color: #ffffff; }

textarea {
  padding: 8px 16px;
  resize: vertical;
  min-height: 6.25rem;
  width: 100%;
  color: #fffcf4;
  transition: border .3s ease-in-out;
  border: 1px solid #aca597;
  background-color: #aca597; }
  textarea:focus {
    outline: none;
    border: 1px solid #000000; }

select {
  background-color: #aca597; }

.select-wrapper {
  position: relative;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .select-wrapper__select {
    height: 2.5rem;
    color: #cccccc;
    border: 1px solid #cccccc;
    font-weight: 500;
    padding: 0 20px; }
    .select-wrapper__select:focus {
      border: 1px solid #cccccc; }
  .select-wrapper__dropdown-icon {
    position: absolute;
    right: 0.625rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none; }
  .select-wrapper option {
    font-size: 18px;
    padding: 12px 0; }

.radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .radio-wrapper__input-wrapper {
    position: relative;
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 0.5rem;
    float: left;
    cursor: pointer;
    flex-shrink: 0; }
    .radio-wrapper__input-wrapper::before {
      content: "";
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      border: 1px solid #aca597;
      background-color: #aca597;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }
  .radio-wrapper__input-dot {
    position: absolute;
    left: 0.3125rem;
    top: 0.3125rem;
    z-index: 2;
    border-radius: 50%;
    background-color: #000000;
    width: 0.9375rem;
    height: 0.9375rem;
    opacity: 0; }
  .radio-wrapper__input, .radio-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .radio-wrapper__input:checked + .radio-wrapper__input-dot, .radio-wrapper input:checked + .radio-wrapper__input-dot {
      opacity: 1; }
  .radio-wrapper__label, .radio-wrapper label {
    font-size: 0.9375rem;
    cursor: pointer;
    font-weight: 400; }

.checkbox-wrapper {
  display: flex;
  align-items: start;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .checkbox-wrapper__input-wrapper {
    position: relative;
    width: 1.5625rem;
    height: 1.5625rem;
    min-width: 1.5625rem;
    min-height: 1.5625rem;
    margin-right: 0.5rem;
    cursor: pointer;
    flex-shrink: 0; }
    .checkbox-wrapper__input-wrapper::before {
      content: "";
      position: absolute;
      z-index: 1;
      border-radius: 0.3125rem;
      border: 1px solid #aca597;
      background-color: #aca597;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }
  .checkbox-wrapper__input-dot {
    position: absolute;
    width: 1rem;
    left: 0.3125rem;
    top: 0.25rem;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0; }
    .checkbox-wrapper__input-dot svg {
      fill: #55b890; }
  .checkbox-wrapper__input, .checkbox-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .checkbox-wrapper__input:checked + .checkbox-wrapper__input-dot, .checkbox-wrapper input:checked + .checkbox-wrapper__input-dot {
      opacity: 1; }
  .checkbox-wrapper__label, .checkbox-wrapper label {
    cursor: pointer;
    margin-top: 0.125rem;
    font-weight: 400; }

.form-message {
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 46px 12px 12px;
  margin: 2rem 0;
  position: relative;
  align-items: center;
  display: none;
  min-height: 3rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .form-message--active {
    display: flex; }
  .form-message--success {
    background-color: #55b890; }
    .form-message--success .form-message__icon--success {
      display: flex; }
  .form-message--error {
    background-color: #e50044; }
    .form-message--error .form-message__icon--error {
      display: flex; }
  .form-message__icon {
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: none;
    border: 1px solid #ffffff; }
    .form-message__icon svg {
      width: 1rem;
      fill: #ffffff; }
  .form-message a {
    color: #ffffff;
    text-decoration: underline; }

.form {
  overflow: hidden;
  transition: all 0.3s ease-in-out; }
  .form__group-wrapper--half .form__group:last-of-type {
    margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .form__group-wrapper--half {
      display: flex;
      margin: 0 -0.5rem; }
      .form__group-wrapper--half .form__group {
        margin: 0 0.5rem 1.5rem;
        width: 50%; } }
  .form__group {
    position: relative;
    margin-bottom: 16px; }
    .form__group:last-of-type {
      margin-bottom: 24px; }
    .form__group--button::after {
      background-color: #a61e19; }
    .form__group--button:hover::after {
      background-color: #d97373; }
  .form__invalid-message {
    color: #e50044; }
  .form__label {
    font-weight: 700;
    display: block;
    padding-bottom: 0.625rem; }
  .form--loading {
    opacity: .5; }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 2rem 0; }
  .video-wrapper__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/* stylelint-disable */
.cc-btn {
  flex: none !important; }

.cc-revoke {
  display: none !important; }

/* stylelint-enable */
/* stylelint-disable */
.grecaptcha-badge {
  display: none !important; }

/* stylelint-enable */
.hero-editor {
  /* stylelint-disable */
  /* stylelint-enable */ }
  .hero-editor__webkey-video {
    position: absolute;
    top: 25%;
    right: 75%;
    z-index: 11;
    background-color: #ffffff; }
  .hero-editor__webkey-video-title {
    margin: 0;
    padding: .75rem; }
  .hero-editor__webkey-video-input {
    border: 0.0625rem solid #000000; }
  .hero-editor #hero-text1 {
    color: #000000; }

.the-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 14;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 14px 0;
  transition: all 0.3s ease-in-out;
  margin: 0 auto; }
  .the-header--tint-dark {
    /* stylelint-disable */
    /* stylelint-enable */ }
    .the-header--tint-dark a {
      color: #ffffff; }
    .the-header--tint-dark .navigation svg path {
      fill: #ffffff; }
  .the-header--scrolled {
    background: #efece5;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .the-header--scrolled.the-header--color-puur-bruin {
      background-color: #7b6d5f; }
    .the-header--scrolled.the-header--color-zacht-bruin {
      background-color: #baad9c; }
    .the-header--scrolled.the-header--color-puur-oker {
      background-color: #b29263; }
    .the-header--scrolled.the-header--color-puur-terra {
      background-color: #936d53; }
    .the-header--scrolled.the-header--color-terra {
      background-color: #be906a; }
    .the-header--scrolled.the-header--color-zacht-terra {
      background-color: #d8bfa2; }
    .the-header--scrolled.the-header--color-puur-rood {
      background-color: #78403a; }
    .the-header--scrolled.the-header--color-zacht-rood {
      background-color: #d3c0b1; }
    .the-header--scrolled.the-header--color-wit-grijs {
      background-color: #efece5; }
    .the-header--scrolled.the-header--color-licht-grijs {
      background-color: #cbc9bc; }
    .the-header--scrolled.the-header--color-koel-grijs {
      background-color: #c7c7c5; }
    .the-header--scrolled.the-header--color-midden-grijs {
      background-color: #a9aeb2; }
    .the-header--scrolled.the-header--color-groen-grijs {
      background-color: #b7aaa0; }
    .the-header--scrolled.the-header--color-warm-grijs {
      background-color: #7d7a75; }
    .the-header--scrolled.the-header--color-donker-grijs {
      background-color: #686667; }
    .the-header--scrolled.the-header--color-zwart-grijs {
      background-color: #36373c; }
    .the-header--scrolled.the-header--color-groen {
      background-color: #7f8270; }
    .the-header--scrolled.the-header--color-puur-groen {
      background-color: #555c55; }
    .the-header--scrolled.the-header--color-zacht oker {
      background-color: #dacebd; }
    .the-header--scrolled.the-header--color-oker {
      background-color: #c1aa88; }
    .the-header--scrolled.the-header--color-zwart {
      background-color: #000000; }
    .the-header--scrolled.the-header--scroll-tint-light a {
      color: #000000; }
    .the-header--scrolled.the-header--scroll-tint-light .navigation svg path {
      fill: #000000; }
    .the-header--scrolled.the-header--scroll-tint-light svg path#logosquare,
    .the-header--scrolled.the-header--scroll-tint-light svg path#logotext {
      fill: #000000; }
  @media (min-width: 768px) {
    .the-header__logo {
      height: auto; } }
  .the-header__logo svg {
    height: 51px;
    width: 115px; }
    @media (min-width: 768px) {
      .the-header__logo svg {
        height: 76px;
        width: 172px; } }

.hamburger {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 1em; }
  @media (min-width: 768px) {
    .hamburger {
      display: none; } }
  .hamburger__box {
    align-self: flex-end; }

.navigation {
  display: flex;
  opacity: 0;
  position: fixed;
  pointer-events: none;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  flex-flow: column;
  align-content: center;
  justify-content: center;
  padding-bottom: 1em;
  background: #a61e19;
  transition: all 0.3s ease-in-out; }
  @media (min-width: 768px) {
    .navigation {
      opacity: 1;
      position: relative;
      flex-flow: row;
      justify-content: flex-end;
      background: none;
      pointer-events: all; } }
  .navigation__list {
    display: flex;
    flex-flow: column;
    align-self: center; }
    @media (min-width: 768px) {
      .navigation__list {
        flex-flow: row;
        align-self: flex-end; } }
  .navigation__listitem {
    opacity: 0;
    position: relative;
    text-align: center;
    transition: all 0.3s ease-in-out; }
    .navigation__listitem::after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: -0.125rem;
      left: 0;
      width: 0;
      height: 0.6875rem;
      font-weight: 700;
      background-color: #a61e19;
      transition: all 0.3s ease-in-out; }
    @media (min-width: 768px) {
      .navigation__listitem {
        opacity: 1;
        text-align: left;
        margin-right: 20px; }
        .navigation__listitem--active::after, .navigation__listitem:hover::after {
          width: 100%; } }
  .navigation__link {
    display: inline-block;
    padding: 0.625rem 0;
    color: #000000;
    font-size: 1.375rem;
    text-decoration: none; }
    .navigation__link--active {
      font-weight: 700; }
    @media (min-width: 768px) {
      .navigation__link {
        padding: 0;
        font-size: 1rem; } }
  .navigation--menuExpanded {
    opacity: 1;
    pointer-events: all; }
    .navigation--menuExpanded .navigation__list {
      transform: translateX(0);
      transition: opacity .3s ease-in-out, transform 0s linear; }
    .navigation--menuExpanded .navigation__listitem {
      transform: translateY(0);
      opacity: 1; }
      .navigation--menuExpanded .navigation__listitem:nth-child(1) {
        transition-delay: 0.05s; }
      .navigation--menuExpanded .navigation__listitem:nth-child(2) {
        transition-delay: 0.1s; }
      .navigation--menuExpanded .navigation__listitem:nth-child(3) {
        transition-delay: 0.15s; }
      .navigation--menuExpanded .navigation__listitem:nth-child(4) {
        transition-delay: 0.2s; }
      .navigation--menuExpanded .navigation__listitem:nth-child(5) {
        transition-delay: 0.25s; }
      .navigation--menuExpanded .navigation__listitem:nth-child(6) {
        transition-delay: 0.3s; }
      .navigation--menuExpanded .navigation__listitem:nth-child(7) {
        transition-delay: 0.35s; }
      .navigation--menuExpanded .navigation__listitem:nth-child(8) {
        transition-delay: 0.4s; }
      .navigation--menuExpanded .navigation__listitem:nth-child(9) {
        transition-delay: 0.45s; }
      .navigation--menuExpanded .navigation__listitem:nth-child(10) {
        transition-delay: 0.5s; }

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block; }

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding-bottom: 3px; }

/* Links inside the dropdown */
.dropdown-content a {
  color: #000000;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1; }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block; }

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41; }

.language-switch {
  display: flex; }
  .language-switch__listitem {
    position: relative;
    list-style: none; }
    .language-switch__listitem::after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: -0.1875rem;
      left: 0;
      width: 0%;
      height: 0.6875rem;
      font-weight: 700;
      background-color: #a61e19;
      transition: all 0.3s ease-in-out; }
    .language-switch__listitem--active {
      display: none; }
    .language-switch__listitem:hover::after {
      width: 100%; }
    @media (min-width: 768px) {
      .language-switch__listitem {
        margin-right: 10px; } }
  .language-switch__link {
    display: inline-block;
    padding: 0.625rem 0;
    color: #000000;
    font-size: 1.375rem;
    text-transform: uppercase;
    text-decoration: none; }
    .language-switch__link--active {
      font-weight: 700; }
    @media (min-width: 768px) {
      .language-switch__link {
        padding: 0;
        font-size: 1rem; } }
    @media (max-width: 767.98px) {
      .language-switch__link__desktop {
        /* stylelint-disable */
        display: none !important;
        /* stylelint-enable */ } }
    .language-switch__link__mobile {
      /* stylelint-disable */
      display: none !important;
      /* stylelint-enable */ }
      @media (max-width: 767.98px) {
        .language-switch__link__mobile {
          /* stylelint-disable */
          display: inline-block !important;
          /* stylelint-enable */ } }
  @media (min-width: 768px) {
    .language-switch {
      margin-right: -20px; } }

.footer {
  padding: 3.75rem 0;
  background: #aca597;
  flex: 1 1 100%;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .footer__copyright-nav {
    color: #ffffff; }
    @media (min-width: 768px) {
      .footer__copyright-nav {
        display: flex; } }
  .footer__nav {
    margin: 20px 0; }
    @media (min-width: 768px) {
      .footer__nav {
        margin: 0 0 0 20px; } }
  .footer__webnl {
    color: #ffffff; }
    @media (min-width: 768px) {
      .footer__webnl {
        text-align: right; } }
  .footer h2, .footer h5, .footer a {
    color: #ffffff; }
  .footer input {
    color: #aca597;
    background-color: #ffffff; }
    .footer input::placeholder {
      color: #a9aeb2; }

.footer-navigation__listitem {
  margin-right: 20px; }

.footer-navigation__link {
  text-decoration: none; }

@media (min-width: 768px) {
  .footer-navigation {
    display: flex; } }

.main {
  overflow: hidden;
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  flex: 1 1 100%;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .main .painting-how .col-24 {
    padding: 0; }
  .main .painting-how__title {
    display: flex;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 992px) {
      .main .painting-how__title {
        max-width: 1200px; } }

.socials {
  display: flex; }
  .socials__listitem {
    position: relative; }
    @media (min-width: 768px) {
      .socials__listitem {
        margin-left: 20px; } }
  .socials__link {
    display: inline-block;
    padding: 0.625rem 0;
    color: #000000;
    font-size: 1.375rem;
    text-transform: uppercase;
    text-decoration: none; }
    .socials__link--active {
      font-weight: 700; }
    @media (min-width: 768px) {
      .socials__link {
        padding: 0;
        font-size: 1rem; } }
    .socials__link svg {
      vertical-align: middle; }
    .socials__link svg path {
      fill: #000000; }

.site {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 100%;
  align-content: space-between; }

.feature-block {
  margin-bottom: 90px; }
  @media (min-width: 768px) {
    .feature-block {
      margin-bottom: 180px; } }
  .feature-block__text-col {
    flex: 0 0 42%; }
  .feature-block__image-col {
    flex: 0 0 58%; }
  .feature-block__imagewrapper {
    position: relative;
    padding-left: 40px;
    padding-bottom: 40px;
    margin-top: 40px; }
    .feature-block__imagewrapper::after {
      content: "";
      position: absolute;
      z-index: 0;
      top: 40px;
      right: 40px;
      bottom: 0;
      left: 0;
      display: block;
      background: #aca597; }
    @media (min-width: 768px) {
      .feature-block__imagewrapper {
        padding-left: 40px;
        padding-right: 40px; }
        .feature-block__imagewrapper::after {
          left: 80px;
          right: 0; } }
  .feature-block__image {
    position: relative;
    z-index: 1;
    width: 100%; }
  .feature-block__button {
    margin-top: 70px; }
  .feature-block--mirrored {
    flex-flow: row-reverse; }
    @media (min-width: 768px) {
      .feature-block--mirrored .feature-block__imagewrapper {
        margin-top: 0;
        padding: 40px; }
        .feature-block--mirrored .feature-block__imagewrapper::after {
          top: 80px;
          right: 80px;
          left: 0; } }
  .feature-block--small-image .feature-block__text-col {
    flex: 0 0 50%; }
  .feature-block--small-image .feature-block__image-col {
    flex: 0 0 50%; }
  @media (min-width: 768px) {
    .feature-block {
      display: flex; } }

.hero {
  height: 100vh;
  overflow: hidden;
  margin-bottom: 5em; }
  .hero__image {
    position: relative;
    height: 100%;
    background: #cccccc; }
    .hero__image::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 33.15%, rgba(0, 0, 0, 0) 65.75%, rgba(0, 0, 0, 0.4) 84%); }
  .hero__imageleft > .hero__bg {
    background-position: left; }
  .hero__imagecenter > .hero__bg {
    background-position: center; }
  .hero__imageright > .hero__bg {
    background-position: right; }
  .hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center; }
  .hero__content {
    position: relative;
    z-index: 12;
    transform: translateY(-99%);
    background: #efece5; }
    .hero__content::before {
      content: "";
      position: absolute;
      top: -1.8125rem;
      left: 0;
      right: 0;
      height: 1.875rem;
      background-color: #efece5;
      mask-image: url("../images/brush-edge.svg");
      mask-size: 100% 100%; }
  .hero__title {
    position: absolute;
    top: -3.75rem;
    transform: translateY(-100%);
    margin-bottom: .25em;
    color: #ffffff; }
  .hero__text {
    font-size: 22px;
    margin-bottom: 2em; }
  .hero__video-wrapper {
    display: flex;
    justify-content: center; }
  .hero__play-button {
    left: 50%;
    position: absolute;
    top: 20%;
    transform: translate(-50%, -50%);
    z-index: 1; }
    @media (min-width: 576px) {
      .hero__play-button {
        top: calc(50% - 3.75rem); } }
    .hero__play-button svg {
      margin: 0; }

.themes__wrapper {
  display: flex;
  flex-flow: wrap; }

.accordion {
  padding-bottom: 1.25rem; }
  .accordion__panel {
    margin-bottom: 10px; }
  .accordion__title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding-bottom: 0.625rem;
    border-bottom: 1px solid #ffffff; }
  .accordion__panel-content {
    max-height: 0;
    padding: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .accordion__panel-content--active {
      max-height: 5000px;
      padding-bottom: 20px; }
    .accordion__panel-content b, .accordion__panel-content i, .accordion__panel-content p, .accordion__panel-content span, .accordion__panel-content strike, .accordion__panel-content strong, .accordion__panel-content u {
      font-family: Avenir, Helvetica, Arial, sans-serif !important;
      font-size: 16px !important; }
  .accordion__panel-status-icon svg {
    width: 16px;
    height: 16px;
    transition: all 0.3s ease-in-out; }
  .accordion__panel-status-icon--active svg {
    transform: rotate(90deg); }
  .accordion--light .accordion__title {
    border-color: #000000; }

.modal {
  position: relative;
  color: #000000;
  /* stylelint-disable */
  /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */ }
  .modal__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.625rem;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #cccccc; }
    .modal__close svg {
      width: 1rem;
      height: 1rem; }
    .modal__close svg path {
      fill: #000000; }
  .modal__body {
    margin: 20px 0; }
  .modal .modal-enter {
    opacity: 0; }
  .modal .modal-leave-active {
    opacity: 0; }
  .modal .modal-enter .modal__container,
  .modal .modal-leave-active .modal__container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  flex-flow: column;
  transition: opacity .3s ease; }

.modal-wrapper {
  width: 100%; }

.modal-container {
  max-width: 100%;
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif; }

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter {
  opacity: 0; }

.modal-leave-active {
  opacity: 0; }

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

/* stylelint-enable */
.creative-commons__list {
  margin-bottom: 1.25rem; }

.creative-commons__listitem {
  display: flex;
  align-items: center;
  padding-bottom: 0.625rem; }
  .creative-commons__listitem svg {
    width: 30px;
    height: 30px;
    margin-right: 0.625rem; }
  .creative-commons__listitem svg path {
    fill: #000000; }

.page--no-hero {
  padding-top: 150px; }

.page--text {
  font-size: 1.375rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .page--text .hero {
    margin-bottom: 0; }
  .page--text .newsletter {
    margin-top: 5rem; }
  .page--text h2 {
    margin: 0; }
  .page--text h5 {
    color: #ffffff; }
  .page--text input {
    background-color: #ffffff; }
    .page--text input::placeholder {
      color: #a9aeb2; }
  .page--text .feature-block {
    margin-top: 3rem;
    margin-bottom: 3rem; }
    .page--text .feature-block__text p {
      margin-left: 5rem; }

.exhibition-card {
  display: block;
  margin-top: 1.5em;
  text-decoration: none; }
  .exhibition-card:hover .exhibition-card__title {
    text-decoration: underline; }
  .exhibition-card__title {
    margin-bottom: 0; }

.painting-filter {
  padding-top: 150px;
  padding-bottom: 50px; }
  .painting-filter__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.875rem;
    align-items: center;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (max-width: 575.98px) {
      .painting-filter__title {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .painting-filter__title {
        flex-direction: row; } }
    .painting-filter__title select {
      color: #a61e19;
      background: none;
      border: none;
      height: 100%;
      direction: rtl;
      padding: 0 0 0 0.5rem;
      text-align: left;
      text-decoration: underline;
      appearance: none;
      background-image: url("/images/icons/red-arrow.svg");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 1.5rem;
      padding-left: 2.5rem; }
      .painting-filter__title select optgroup {
        font-size: 1rem; }
      @media (max-width: 575.98px) {
        .painting-filter__title select {
          font-size: 2rem; } }
      @media (max-width: 1199.98px) {
        .painting-filter__title select {
          margin-top: .5rem; } }
      @media (min-width: 1200px) {
        .painting-filter__title select {
          margin-left: 1.5rem; } }
  .painting-filter__select {
    display: flex; }
  .painting-filter__icon {
    width: 1.5rem;
    align-items: center;
    line-height: 65px; }
    @media (min-width: 768px) {
      .painting-filter__icon {
        margin-left: 1rem; } }
    .painting-filter__icon svg path {
      fill: #323232; }
  .painting-filter__option::after {
    content: "";
    position: absolute;
    bottom: -0.75rem;
    left: 0;
    right: 0;
    height: 0.9375rem;
    background-color: #aca597;
    mask-image: url("../images/brush-stroke.svg");
    mask-size: 100% 100%;
    transition: all 0.3s ease-in-out; }

.painting-timeline__cards {
  margin-left: 1rem; }

.painting-timeline__title {
  position: relative;
  margin-left: 23px; }
  .painting-timeline__title::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translateY(-50%);
    height: 1rem;
    width: 0.375rem;
    background-color: #000000;
    mask-image: url("../images/icons/caret-right-solid.svg");
    transition: all 0.3s ease-in-out; }

.painting-timeline__card-wrapper {
  margin-left: 23px; }
  @media (min-width: 768px) {
    .painting-timeline__card-wrapper {
      margin-left: 0; } }

.painting-detail {
  padding-top: 120px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 768px) {
    .painting-detail {
      display: flex; } }
  @media (min-width: 992px) {
    .painting-detail {
      max-width: 1200px; } }
  @media (min-width: 768px) {
    .painting-detail__image-wrapper {
      margin-right: 20px;
      transition: all 0.3s ease-in-out; } }
  @media (min-width: 1440px) {
    .painting-detail__image-wrapper {
      max-width: 850px; } }
  .painting-detail__image-wrapper--focus {
    position: fixed;
    z-index: 14;
    top: 0;
    left: 0;
    overflow: auto;
    padding-bottom: 80px;
    width: 100vw;
    max-width: none;
    height: 100vh; }
    .painting-detail__image-wrapper--focus .painting-detail__image {
      width: 100vw;
      max-height: none; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 20px;
      background-color: #efece5;
      transition: all 0.3s ease-in-out; }
    .painting-detail__image-wrapper--focus .painting-detail__image-button {
      margin-bottom: 0;
      transition: all 0.3s ease-in-out; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-puur-bruin {
      background-color: #7b6d5f; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-puur-bruin {
      background-color: #7b6d5f; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-zacht-bruin {
      background-color: #baad9c; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-zacht-bruin {
      background-color: #baad9c; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-puur-oker {
      background-color: #b29263; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-puur-oker {
      background-color: #b29263; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-puur-terra {
      background-color: #936d53; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-puur-terra {
      background-color: #936d53; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-terra {
      background-color: #be906a; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-terra {
      background-color: #be906a; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-zacht-terra {
      background-color: #d8bfa2; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-zacht-terra {
      background-color: #d8bfa2; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-puur-rood {
      background-color: #78403a; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-puur-rood {
      background-color: #78403a; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-zacht-rood {
      background-color: #d3c0b1; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-zacht-rood {
      background-color: #d3c0b1; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-wit-grijs {
      background-color: #efece5; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-wit-grijs {
      background-color: #efece5; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-licht-grijs {
      background-color: #cbc9bc; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-licht-grijs {
      background-color: #cbc9bc; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-koel-grijs {
      background-color: #c7c7c5; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-koel-grijs {
      background-color: #c7c7c5; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-midden-grijs {
      background-color: #a9aeb2; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-midden-grijs {
      background-color: #a9aeb2; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-groen-grijs {
      background-color: #b7aaa0; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-groen-grijs {
      background-color: #b7aaa0; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-warm-grijs {
      background-color: #7d7a75; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-warm-grijs {
      background-color: #7d7a75; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-donker-grijs {
      background-color: #686667; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-donker-grijs {
      background-color: #686667; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-zwart-grijs {
      background-color: #36373c; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-zwart-grijs {
      background-color: #36373c; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-groen {
      background-color: #7f8270; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-groen {
      background-color: #7f8270; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-puur-groen {
      background-color: #555c55; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-puur-groen {
      background-color: #555c55; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-zacht oker {
      background-color: #dacebd; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-zacht oker {
      background-color: #dacebd; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-oker {
      background-color: #c1aa88; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-oker {
      background-color: #c1aa88; }
    .painting-detail__image-wrapper--focus .painting-detail__image-buttons--color-zwart {
      background-color: #000000; }
    .painting-detail__image-wrapper--focus.painting-detail__image-wrapper--color-zwart {
      background-color: #000000; }
  .painting-detail__background-wrapper {
    position: relative; }
    @media (min-width: 768px) {
      .painting-detail__background-wrapper {
        max-height: calc(100vh - 180px); } }
  .painting-detail__background-image {
    background-repeat: no-repeat;
    bottom: 0;
    cursor: zoom-in;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }
  .painting-detail__image {
    max-width: 100%; }
    @media (min-width: 768px) {
      .painting-detail__image {
        max-height: calc(100vh - 180px);
        transition: all 0.3s ease-in-out; } }
  .painting-detail__image-buttons {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 768px) {
      .painting-detail__image-buttons {
        justify-content: space-between; } }
  .painting-detail__image-buttons-navigation {
    flex-grow: 1; }
  @media (min-width: 768px) {
    .painting-detail__image-buttons-focus {
      min-width: 210px; } }
  .painting-detail__image-button {
    display: inline-flex;
    justify-content: space-between;
    font-size: 0;
    font-weight: 700;
    padding: 4px 0;
    margin-right: 0; }
    @media (min-width: 768px) {
      .painting-detail__image-button {
        font-size: 1rem;
        padding: 4px 24px 4px 0; } }
    .painting-detail__image-button svg {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px 0 0; }
    .painting-detail__image-button--next svg {
      margin: 0 0 0 10px; }
    .painting-detail__image-button--previous, .painting-detail__image-button--next {
      max-width: 45%; }
  .painting-detail__image-button-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1; }
  @media (min-width: 768px) {
    .painting-detail__caption {
      width: 300px;
      justify-self: flex-end;
      margin-left: auto;
      padding-bottom: 20px; } }
  .painting-detail__title {
    margin-bottom: 2.0625rem; }
  .painting-detail__intro {
    padding-bottom: 3.125rem; }
  .painting-detail__download-button {
    margin-bottom: 1.2em; }

/* stylelint-disable */
.rsbtn_play {
  background-color: #a61e19 !important;
  border: none !important;
  padding: .25rem !important;
  border-radius: 3px !important; }
  .rsbtn_play:hover {
    background-color: #d97373 !important;
    transition: all .3s ease-in-out; }
    .rsbtn_play:hover .rsbtn_right::before {
      color: #ffffff !important; }

.rsbtn_left span,
.rsbtn_right {
  color: #ffffff !important;
  border-left: none !important; }

.rsbtn_tooltoggle {
  background-color: #a61e19 !important;
  border: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 0 !important;
  transform: translateY(-50%) !important; }
  .rsbtn_tooltoggle:hover {
    background-color: #d97373 !important;
    transition: all .3s ease-in-out; }

.rs_addtools a,
.rs_addtools span,
.rsbtn a,
.rsbtn span {
  color: #ffffff !important; }

.rsbtn_tools_inner {
  background-color: #a61e19 !important; }

.rsbtn_tool:hover {
  background-color: #d97373 !important; }

.rsbtn_exp_inner {
  border: 0 !important;
  margin-top: 10px !important;
  border-radius: 3px !important;
  display: inline-flex !important; }

.rs-player-loadindicator {
  display: none !important; }

.rsbtn_status {
  background-color: #a61e19 !important; }

.rsbtn_player_item {
  background-color: #a61e19 !important;
  border: 0 !important; }
  .rsbtn_player_item:hover {
    background-color: #d97373 !important; }

.rsbtn.rsexpanded {
  margin-bottom: 45px; }

#rsbtn_scrollcontrols {
  background-color: #a61e19 !important;
  border: 0 !important; }
  #rsbtn_scrollcontrols button,
  #rsbtn_scrollcontrols .rsbtn_scroll_state {
    color: #ffffff !important;
    border: 0 !important; }
    #rsbtn_scrollcontrols button:hover, #rsbtn_scrollcontrols button:focus,
    #rsbtn_scrollcontrols .rsbtn_scroll_state:hover,
    #rsbtn_scrollcontrols .rsbtn_scroll_state:focus {
      background-color: #d97373 !important; }

/* stylelint-enable */
.painting-share__list {
  margin-top: 0.625rem; }

.painting-share__listitem {
  padding-bottom: 0.625rem; }

.painting-share__link {
  text-decoration: none; }
  .painting-share__link svg {
    vertical-align: middle;
    margin-right: 0.625rem; }
  .painting-share__link--facebook svg path {
    fill: #3b5998; }
  .painting-share__link--twitter svg path {
    fill: #1da1f2; }
  .painting-share__link--linkedin svg path {
    fill: #0077b5; }
  .painting-share__link--pinterest svg path {
    fill: #bd081c; }
  .painting-share__link--instagram svg path {
    fill: #c13584; }

.painting-card {
  margin-bottom: 60px;
  margin-right: 20px; }
  .painting-card--small .painting-card__image-wrapper {
    min-height: 200px; }
    @media (min-width: 768px) {
      .painting-card--small .painting-card__image-wrapper {
        min-height: 240px; } }
  .painting-card--small .painting-card__image {
    max-height: 240px; }
  .painting-card__overlay-link {
    text-decoration: none;
    display: inline-block; }
    .painting-card__overlay-link:hover .painting-card__image-wrapper::after {
      background-color: rgba(50, 50, 50, 0.8); }
    .painting-card__overlay-link:hover .painting-card__button {
      opacity: 1;
      transform: translate(-50%, -50%); }
  .painting-card__image-wrapper {
    position: relative;
    width: fit-content;
    min-height: 250px; }
    @media (min-width: 768px) {
      .painting-card__image-wrapper {
        min-height: 360px; } }
    .painting-card__image-wrapper::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0.3125rem;
      left: 0;
      transition: all 0.3s ease-in-out; }
  .painting-card__button {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    display: block;
    white-space: nowrap;
    transition: all 0.3s ease-in-out; }
  .painting-card__image {
    max-height: 360px;
    max-width: 100%; }

.painting-slider {
  /* stylelint-disable */
  /* stylelint-enable */ }
  .painting-slider__card {
    flex: 1 1 100%; }
    .painting-slider__card--inner {
      color: #ffffff;
      background-position: center center;
      background-size: cover;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 100%;
      position: relative;
      z-index: 1;
      align-content: space-between;
      pointer-events: none;
      cursor: default; }
      .painting-slider__card--inner::after {
        content: "";
        background: linear-gradient(360deg, #0d0d1c 0%, rgba(13, 13, 28, 0) 100%);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%; }
  .painting-slider--nav {
    position: absolute;
    bottom: 5%;
    left: auto;
    right: 0;
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
    padding-left: 15px;
    padding-right: 15px; }
  .painting-slider--nav-item {
    background-color: #a61e19;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    margin: 0 1rem;
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    transition: all .3s ease-in-out; }
  .painting-slider__text {
    position: absolute;
    bottom: 10%;
    left: 15%;
    max-width: 300px;
    z-index: 1; }
  .painting-slider__text-side {
    position: absolute;
    bottom: 3rem;
    transform: rotate(270deg);
    z-index: 1; }
    @media (min-width: 768px) {
      .painting-slider__text-side {
        bottom: 4rem; } }
  .painting-slider__img {
    width: 100%;
    height: 100%; }
  .painting-slider .flickity-slider {
    display: flex; }
  .painting-slider .flickity-viewport {
    overflow: visible; }

.painting-slide {
  width: 100%;
  display: inline-flex;
  transition: height 0s 2.5s linear; }
  .painting-slide::after {
    content: "";
    border-top: 1px solid #ffffff;
    position: absolute;
    bottom: .5rem;
    width: 100%;
    left: 0;
    margin-left: 30px;
    z-index: 1; }

.flickity-loaded .painting-slide {
  height: 100%; }

.painting-video__no-cookies {
  background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.75rem, rgba(0, 0, 0, 0.1) 0.75rem, rgba(0, 0, 0, 0.1) 1.25rem) #ffffff;
  border: 0.0625rem solid #cccccc;
  color: #000000;
  font-weight: 500;
  padding: 1.5rem;
  position: relative;
  text-align: center;
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
