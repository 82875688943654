.painting-video {
    &__no-cookies {
        background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) .75rem, rgba(0, 0, 0, .1) .75rem, rgba(0, 0, 0, .1) 1.25rem) map-deep-get($colors, "white", "default");
        border: .0625rem solid map-deep-get($colors, "gray", "default");
        color: map-deep-get($colors, "black", "default");
        font-weight: 500;
        padding: 1.5rem;
        position: relative;
        text-align: center;
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}
