.button {
    position: relative;
    padding: 4px 12px;
    margin-bottom: 12px;
    margin-right: 24px;
    min-height: rem-calc(40);
    border-radius: 3px;
    background-color: map-deep-get($colors, "primary", "default");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 900;
    font-size: 22px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    $button: &;

    @include media-breakpoint-up(md) {
        font-size: 26px;
    }

    svg {
        width: rem-calc(30);
        height: rem-calc(30);
        margin-left: rem-calc(-8);
        margin-right: rem-calc(8);
        display: inline-block;
        vertical-align: middle;
        transition: $transition-default;
    }

    svg path {
        fill: map-deep-get($colors, "white", "default");
    }

    &::after {
        content: "";
        position: absolute;
        bottom: rem-calc(-12);
        left: -1px;
        right: -1px;
        height: rem-calc(15);
        background-color: map-deep-get($colors, "primary", "default");
        mask-image: url("../images/brush-stroke.svg");
        mask-size: 100% 100%;
        transition: $transition-default;
    }

    &:hover,
    &:focus {
        background-color: map-deep-get($colors, "primary", "light");

        &::after {
            background-color: map-deep-get($colors, "primary", "light");
        }
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--small {
        font-size: 16px;
        min-height: rem-calc(30);
    }

    &--ghost {
        color: map-deep-get($colors, "black", "default");
        background: transparent;

        svg path {
            fill: map-deep-get($colors, "black", "default");
        }

        &:hover,
        &:focus {
            background: transparent;

            svg {
                transform: scale(1.3);
            }
        }

        &::after {
            display: none;
        }
    }

    &--light {
        svg path {
            fill: map-deep-get($colors, "black", "default");
        }
    }

    &--black {
        background-color: map-deep-get($colors, "black", "default");

        &::after {
            background-color: map-deep-get($colors, "black", "default");
        }
    }

    &:not(#{$button}--ghost) {
        color: map-deep-get($colors, "white", "default");
    }
}
