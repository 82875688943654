.painting-timeline {
    &__cards {
        margin-left: 1rem;
    }

    &__title {
        position: relative;
        margin-left: 23px;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: -12px;
            transform: translateY(-50%);
            height: rem-calc(16);
            width: rem-calc(6);
            background-color: map-deep-get($colors, "black", "default");
            mask-image: url("../images/icons/caret-right-solid.svg");
            transition: $transition-default;
        }
    }

    &__card-wrapper {
        margin-left: 23px;

        @include media-breakpoint-up(md) {
            margin-left: 0;
        }
    }
}
