.painting-card {
    margin-bottom: 60px;
    margin-right: 20px;
    $painting-card: &;

    &--small {
        #{$painting-card}__image-wrapper {
            min-height: 200px;

            @include media-breakpoint-up(md) {
                min-height: 240px;
            }
        }

        #{$painting-card}__image {
            max-height: 240px;
        }
    }

    &__overlay-link {
        text-decoration: none;
        display: inline-block;

        &:hover {
            #{$painting-card}__image-wrapper::after {
                background-color: map-deep-get($colors, "overlay", "default");
            }

            #{$painting-card}__button {
                opacity: 1;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__image-wrapper {
        position: relative;
        width: fit-content;
        min-height: 250px;

        @include media-breakpoint-up(md) {
            min-height: 360px;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: rem-calc(5);
            left: 0;
            transition: $transition-default;
        }
    }

    &__button {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: 0;
        display: block;
        white-space: nowrap;
        transition: $transition-default;
    }

    &__image {
        max-height: 360px;
        max-width: 100%;
    }
}
