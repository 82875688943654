.feature-block {
    margin-bottom: 90px;
    $feature-block: &;

    @include media-breakpoint-up(md) {
        margin-bottom: 180px;
    }

    &__text-col {
        flex: 0 0 42%;
    }

    &__image-col {
        flex: 0 0 58%;
    }

    &__imagewrapper {
        position: relative;
        padding-left: 40px;
        padding-bottom: 40px;
        margin-top: 40px;

        &::after {
            content: "";
            position: absolute;
            z-index: 0;
            top: 40px;
            right: 40px;
            bottom: 0;
            left: 0;
            display: block;
            background: map-deep-get($colors, "secondary", "dark");
        }

        @include media-breakpoint-up(md) {
            padding-left: 40px;
            padding-right: 40px;

            &::after {
                left: 80px;
                right: 0;
            }
        }
    }

    &__image {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    &__button {
        margin-top: 70px;
    }

    &--mirrored {
        flex-flow: row-reverse;

        #{$feature-block} {
            @include media-breakpoint-up(md) {
                &__imagewrapper {
                    margin-top: 0;
                    padding: 40px;

                    &::after {
                        top: 80px;
                        right: 80px;
                        left: 0;
                    }
                }
            }
        }
    }

    &--small-image {
        #{$feature-block} {
            &__text-col {
                flex: 0 0 50%;
            }

            &__image-col {
                flex: 0 0 50%;
            }
        }
    }

    @include media-breakpoint-up(md) {
        display: flex;
    }
}