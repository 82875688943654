﻿/// <reference path="../_reference.scss" />
*, *::before, *::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html, body {
    margin: 0 auto;
    padding: 0;
    font-family: $font-default;
    font-weight: $font-weight-base;
    color: map-deep-get($colors, "black", "default");
    background: map-deep-get($colors, "secondary", "default");
}

/* stylelint-disable */
span, p, i, b, u, strong, strike {
    font-family: $font-default !important;
}
/* stylelint-enable */
.body {
    @each $name, $color in $background-colors {
        &--color-#{$name} {
            background-color: $color;
        }
    }

    &--tint-dark {
        color: map-deep-get($colors, "white", "default");

        /* stylelint-disable */
        a {
            color: map-deep-get($colors, "white", "default");
        }

        .button--ghost svg path {
            fill: map-deep-get($colors, "white", "default");
        }

        /* stylelint-enable */
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    color: map-deep-get($colors, "black", "default");
}

b, strong {
    font-weight: 700;
}

::selection {
    background-color: map-deep-get($colors, "primary", "default");
    color: map-deep-get($colors, "white", "default");
}

[v-cloak] {
    display: none;
}
