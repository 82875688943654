.hero-editor {
    &__webkey-video {
        position: absolute;
        top: 25%;
        right: 75%;
        z-index: 11;
        background-color: map-deep-get($colors, "white", "default");
    }

    &__webkey-video-title {
        margin: 0;
        padding: .75rem;
    }

    &__webkey-video-input {
        border: .0625rem solid map-deep-get($colors, "black", "default");
    }

    /* stylelint-disable */
    #hero-text1 {
        color: map-deep-get($colors, "black", "default");
    }
    /* stylelint-enable */
}
