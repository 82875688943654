.language-switch {
    display: flex;

    &__listitem {
        position: relative;
        list-style: none;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            bottom: rem-calc(-3);
            left: 0;
            width: 0%;
            height: rem-calc(11);
            font-weight: 700;
            background-color: map-deep-get($colors, "primary", "default");
            transition: $transition-default;
        }

        &--active {
            display: none;
        }

        &:hover {
            &::after {
                width: 100%;
            }
        }

        @include media-breakpoint-up(md) {
            margin-right: 10px;
        }
    }

    &__link {
        display: inline-block;
        padding: rem-calc(10) 0;
        color: map-deep-get($colors, "black", "default");
        font-size: rem-calc(22);
        text-transform: uppercase;
        text-decoration: none;

        &--active {
            font-weight: 700;
        }

        @include media-breakpoint-up(md) {
            padding: 0;
            font-size: rem-calc(16);
        }

        &__desktop {
            @include media-breakpoint-down(sm) {
                /* stylelint-disable */
                display: none !important;
                /* stylelint-enable */
            }
        }

        &__mobile {
            /* stylelint-disable */
            display: none !important;
            /* stylelint-enable */

            @include media-breakpoint-down(sm) {
                /* stylelint-disable */
                display: inline-block !important;
                /* stylelint-enable */
            }
        }
    }

    @include media-breakpoint-up(md) {
        margin-right: -20px;
    }
}
