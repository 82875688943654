.painting-filter {
    padding-top: 150px;
    padding-bottom: 50px;

    &__title {
        display: flex;
        flex-direction: column;
        margin-bottom: rem-calc(46);
        align-items: center;

        @include media-breakpoint-down(xs) {
            font-size: 2rem;
        }

        @include media-breakpoint-up(xl) {
            flex-direction: row;
        }

        /* stylelint-disable */
        select {
            @extend .h2;
            color: map-deep-get($colors, "primary", "default");
            background: none;
            border: none;
            height: 100%;
            direction: rtl;
            padding: 0 0 0 0.5rem;
            text-align: left;
            text-decoration: underline;
            appearance: none;
            background-image: url("/images/icons/red-arrow.svg");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 1.5rem;
            padding-left: 2.5rem;

            optgroup {
                font-size: 1rem;
            }

            @include media-breakpoint-down(xs) {
                font-size: 2rem;
            }

            @include media-breakpoint-down(lg) {
                margin-top: .5rem;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 1.5rem;
            }
        }

        /* stylelint-enable */
    }

    &__select {
        display: flex;
    }

    &__icon {
        width: 1.5rem;
        align-items: center;
        line-height: 65px;

        @include media-breakpoint-up(md) {
            margin-left: 1rem;
        }

        svg path {
            fill: #323232;
        }
    }

    &__option {
        &::after {
            content: "";
            position: absolute;
            bottom: rem-calc(-12);
            left: 0;
            right: 0;
            height: rem-calc(15);
            background-color: map-deep-get($colors, "secondary", "dark");
            mask-image: url("../images/brush-stroke.svg");
            mask-size: 100% 100%;
            transition: $transition-default;
        }
    }
}
