.creative-commons {
    &__list {
        margin-bottom: rem-calc(20);
    }

    &__listitem {
        display: flex;
        align-items: center;
        padding-bottom: rem-calc(10);

        svg {
            width: 30px;
            height: 30px;
            margin-right: rem-calc(10);
        }

        svg path {
            fill: map-deep-get($colors, "black", "default");
        }
    }
}