.painting-slide {
    width: 100%;
    display: inline-flex;
    transition: height 0s 2.5s linear;

    &::after {
        content: "";
        border-top: 1px solid map-deep-get($colors, "white", "default");
        position: absolute;
        bottom: .5rem;
        width: 100%;
        left: 0;
        margin-left: 30px;
        z-index: 1;
    }
}

.flickity-loaded .painting-slide {
    height: 100%;
}
