.accordion {
    padding-bottom: rem-calc(20px);
    $accordion: &;

    &__panel {
        margin-bottom: 10px;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding-bottom: rem-calc(10);
        border-bottom: 1px solid map-deep-get($colors, "white", "default");
    }

    &__panel-content {
        max-height: 0;
        padding: 0;
        overflow: hidden;
        transition: $transition-default;

        &--active {
            max-height: 5000px;
            padding-bottom: 20px;
        }

        /* stylelint-disable */
        b, i, p, span, strike, strong, u {
            font-family: Avenir, Helvetica, Arial, sans-serif !important;
            font-size: 16px !important;
        }
        /* stylelint-enable */
    }

    &__panel-status-icon {
        svg {
            width: 16px;
            height: 16px;
            transition: $transition-default;
        }

        &--active {
            svg {
                transform: rotate(90deg);
            }
        }
    }

    &--light {
        #{$accordion}__title {
            border-color: map-deep-get($colors, "black", "default");
        }
    }
}
