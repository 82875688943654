// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    .intro {
        margin-top: 0;
        margin-bottom: 1.75em;
    }

    p {
        margin: 0 0 1em;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 1em 0 1em;
    }

    img {
        max-width: 100%;
    }
}

/* stylelint-enable */