.the-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 14;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: 14px 0;
    transition: $transition-default;
    margin: 0 auto;
    $header: &;

    &--tint-dark {
        /* stylelint-disable */
        a {
            color: map-deep-get($colors, "white", "default");
        }

        .navigation svg path {
            fill: map-deep-get($colors, "white", "default");
        }
        /* stylelint-enable */
    }

    &--scrolled {
        background: map-deep-get($colors, "secondary", "default");

        /* stylelint-disable */
        @each $name, $color in $background-colors {
            &#{$header}--color-#{$name} {
                background-color: $color;
            }
        }

        &#{$header}--scroll-tint-light a {
            color: map-deep-get($colors, "black", "default");
        }

        &#{$header}--scroll-tint-light .navigation svg path {
            fill: map-deep-get($colors, "black", "default");
        }

        &#{$header}--scroll-tint-light svg path#logosquare,
        &#{$header}--scroll-tint-light svg path#logotext {
            fill: map-deep-get($colors, "black", "default");
        }
        /* stylelint-enable */
    }

    &__logo {
        @include media-breakpoint-up(md) {
            height: auto;
        }

        svg {
            height: 51px;
            width: 115px;

            @include media-breakpoint-up(md) {
                height: 76px;
                width: 172px;
            }
        }
    }
}