.footer-navigation {
    &__listitem {
        margin-right: 20px;
    }

    &__link {
        text-decoration: none;
    }

    @include media-breakpoint-up(md) {
        display: flex;
    }
}