input, select {
    height: rem-calc(40);
    /* stylelint-disable */
    margin-bottom: 0 !important;
    /* stylelint-enable */
    padding: 0 rem-calc(16);
    width: 100%;
    color: map-deep-get($colors, "secondary", "light");
    transition: $transition-default;
    border: 1px solid map-deep-get($colors, "secondary", "dark");
    background-color: map-deep-get($colors, "secondary", "dark");

    &:focus {
        outline: none;
        border: 1px solid map-deep-get($colors, "black", "default");
    }

    &::placeholder {
        color: map-deep-get($colors, "white", "default");
    }
}

.placeholder {
    color: map-deep-get($colors, "gray", "dark");
}

textarea {
    padding: 8px 16px;
    resize: vertical;
    min-height: rem-calc(100);
    width: 100%;
    color: map-deep-get($colors, "secondary", "light");
    transition: border .3s ease-in-out;
    border: 1px solid map-deep-get($colors, "secondary", "dark");
    background-color: map-deep-get($colors, "secondary", "dark");

    &:focus {
        outline: none;
        border: 1px solid map-deep-get($colors, "black", "default");
    }
}

select {
    background-color: map-deep-get($colors, "secondary", "dark");
}

.select-wrapper {
    position: relative;

    &__select {
        height: rem-calc(40);
        color: map-deep-get($colors, "gray", "default");
        border: 1px solid map-deep-get($colors, "gray", "default");
        font-weight: 500;
        padding: 0 20px;

        &:focus {
            border: 1px solid map-deep-get($colors, "gray", "default");
        }
    }

    &__dropdown-icon {
        position: absolute;
        right: rem-calc(10);
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
    }

    //Directly referencing option element because of select context.
    /* stylelint-disable */
    option {
        font-size: 18px;
        padding: 12px 0;
    }

    /* stylelint-enable */
}

//Radio Button styling
.radio-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(8);
    $radioWrapper: &;

    &__input-wrapper {
        position: relative;
        width: rem-calc(25);
        height: rem-calc(25);
        margin-right: rem-calc(8);
        float: left;
        cursor: pointer;
        flex-shrink: 0;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: 50%;
            border: 1px solid map-deep-get($colors, "secondary", "dark");
            background-color: map-deep-get($colors, "secondary", "dark");
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__input-dot {
        position: absolute;
        left: rem-calc(5);
        top: rem-calc(5);
        z-index: 2;
        border-radius: 50%;
        background-color: map-deep-get($colors, "black", "default");
        width: rem-calc(15);
        height: rem-calc(15);
        opacity: 0;
    }

    // Default input styling uit Back-End heeft geen class
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: rem-calc(-999);

        &:checked {
            + #{$radioWrapper}__input-dot {
                opacity: 1;
            }
        }
    }

    &__label, label {
        font-size: rem-calc(15);
        cursor: pointer;
        font-weight: 400;
    }

    /* stylelint-enable */
}

.checkbox-wrapper {
    display: flex;
    align-items: start;
    margin-bottom: rem-calc(8);
    $checkboxWrapper: &;

    &__input-wrapper {
        position: relative;
        width: rem-calc(25);
        height: rem-calc(25);
        min-width: rem-calc(25);
        min-height: rem-calc(25);
        margin-right: rem-calc(8);
        cursor: pointer;
        flex-shrink: 0;

        &::before {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: rem-calc(5);
            border: 1px solid map-deep-get($colors, "secondary", "dark");
            background-color: map-deep-get($colors, "secondary", "dark");
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__input-dot {
        position: absolute;
        width: rem-calc(16);
        left: rem-calc(5);
        top: rem-calc(4);
        right: 0;
        bottom: 0;
        z-index: 2;
        opacity: 0;

        svg {
            fill: map-deep-get($colors, "validation", "valid");
        }
    }

    // Default input styling uit Back-End heeft geen class
    /* stylelint-disable */
    &__input, input {
        line-height: 1;
        position: absolute;
        left: rem-calc(-999);

        &:checked {
            + #{$checkboxWrapper}__input-dot {
                opacity: 1;
            }
        }
    }

    &__label, label {
        cursor: pointer;
        margin-top: rem-calc(2);
        font-weight: 400;
    }

    /* stylelint-enable */
}
